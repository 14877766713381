<template>
  <div class="modify-mobile">
    <el-form :inline-message="true" :model="ruleForm" :rules="rules" label-width="110px"
      ref="ruleForm">
      <div class="tabs">
        <span :class="verifyType === 'MOBILE' ? 'tab mobile active' : 'tab mobile'"
          @click="verifyType = 'MOBILE'">手机号验证</span>
        <span :class="verifyType === 'EMAIL' ? 'tab email active' : 'tab email'"
          @click="verifyType = 'EMAIL'">邮箱验证</span>
      </div>
      <!-- 手机号验证  -->
      <div v-if="verifyType === 'MOBILE'">
        <div class="info">
          <div><span class="label">当前手机号：</span>{{ ruleForm.mobile }} </div>
          <getVerificationCode :mobile="ruleForm.mobile" class="modify-mobile__btn--code" /> 
        </div>
        <el-form-item label="验证码" prop="verifyCode">
          <el-input class="verifyCode" v-model="ruleForm.verifyCode"  type="text" maxlength="4"></el-input>
        </el-form-item>
      </div>

      <!-- 邮箱验证 -->

      <div v-else>
        <div class="info">
          <div><span class="label">当前邮箱：</span>{{ ruleForm.email }} </div>
          <div class="modify-mobile__btn--code" @click="getCode" >{{tips}}</div>
        </div>
        <div class="tips">获取验证码后，请前往该邮箱进行验证</div>
      </div>

      <div class="btn-wrap">
        <el-button :loading="saveLoading" @click="submitForm('ruleForm')"
          class="modify-mobile__btn--save" type="primary"><span v-if="verifyType === 'EMAIL'">已验证，</span> 下一步</el-button>
      </div>

    </el-form>
  </div>
</template>
<script>
import getVerificationCode from '@/components/getVerificationCode';
import { getBasicInfo } from '@/api/shop';
import { throttleAt } from '@/utils/enhance';
import { distributorChangeMobileValidEmail, distributorChangeMobileValidateVerifyCode, distributorChangeMobileSendEmail } from '@/api/login/modify-mobile';
export default {
  name: 'modify-mobile',
  components: { getVerificationCode },
  data() {
    return {
      timeCount: null,
      verifyType: 'MOBILE', // 验证类型 MOBILE  :手机号 EMAIL  ：邮件
      ruleForm: {
        mobile: '',
        verifyCode: '',
        email: ''
      },
      rules: {
        verifyCode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      tips: '获取验证码',
      isClick: false,
      saveLoading: false,
      beforeUrl: '',
    };
  },
  created() {
    this.fetchBasicInfo();
  },
  methods: {
    fetchBasicInfo() {
      getBasicInfo().then(res => {
        const distributorVO = res.data.distributorVO || {};
        this.ruleForm = {
            mobile: distributorVO.applyMobile,
            email: distributorVO.email
        };
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
      // 邮箱验证
      if (this.verifyType === 'EMAIL') {
        const data = await distributorChangeMobileValidEmail({ id: '' });
        if (data.success) {
          this.$router.push(`/syoung/information/modify-mobile-change?verifyType=${this.verifyType}`);
        } else {
          this.$message.error(data.msg);
        }
      } else {
        // 手机号验证
        if (!this.ruleForm.mobile || this.ruleForm.mobile.trim().length !== 11) {
          this.$message.error('请正确输入手机号');
          return;
        }
        if (!this.ruleForm.verifyCode || this.ruleForm.verifyCode.trim().length === 0) {
          this.$message.error('请输入验证码');
          return;
        }
        console.log('this.ruleForm.verifyCode', this.ruleForm.verifyCode);
       await distributorChangeMobileValidateVerifyCode(
          this.ruleForm.mobile.trim(),
          this.ruleForm.verifyCode.trim()
        );
        // 手机号验证
          this.$router.push(`/syoung/information/modify-mobile-change?verifyType=${this.verifyType}&oldMobileVerifyCode=${this.ruleForm.verifyCode}`);
      }
        } else {
          return false;
        }
      });
    },
    getCode() {
      throttleAt(async () => {
        if (!this.ruleForm.mobile || !/^1\d{10}$/.test(this.ruleForm.mobile)) {
        this.$message.error('请正确输入手机号');
        return;
      }

      if (this.isClick) return;
      const data = await distributorChangeMobileSendEmail({});
      if (data.success) {
        this.isClick = true;
        this.$message.success('发送成功');
        let sec = 120;
        this.tips = `${sec} s`;
        this.timeCount = setInterval(() => {
          this.tips = `${sec} s`;
          if (!sec || sec === 0 || sec < 0) {
            clearInterval(this.timeCount);
            this.isClick = false;
            this.tips = '获取验证码';
          }
          sec--;
        }, 1000);
      } else {
        this.$message.error(data.msg);
      }
      }, 500)();
    },
  },
};
</script>
<style lang="scss" scoped>
.modify-mobile {
  padding-top: 136px;
  padding-bottom:350px;

  .el-form {
    width: 400px;
    margin: 0 auto;
  }

  .tabs {
    display: flex;
    justify-content: center;

    .tab {
      cursor: pointer;
      width: 90px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #AB0033;
      color: #AB0033;

      &.active {

        background: #AB0033;
        color: #fff;
      }

      &.mobile {
        border-radius: 3px 0px 0px 3px;
      }

      &.email {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }

  .info {
    font-size: 15px;
    margin: 30px 0;
    font-weight: 600;
    color: #333333;
    line-height: 21px;
    display: flex;
    justify-content: center;

    .label {
      width: 110px;
      text-align: right;
    }

    .modify-mobile__btn--code {
      color: #AB0033;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
  }

  &__btn {
    &--save {
      font-size: 18px;
      color: #fff;
      margin-top: 100px;
      width: 180px;
      height: 40px;
      background: #BD002F;
      border-radius: 2px;
    }
  }
}
.tips{
  text-align: center;
}
</style>